import { useEffect, useRef, useState } from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import Layout from 'src/Layout';
import {
  SitecoreContext,
  ComponentPropsContext,
  StaticPath,
  GraphQLErrorPagesService,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { handleEditorFastRefresh } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { componentBuilder } from 'temp/componentBuilder';
import { sitemapFetcher } from 'lib/sitemap-fetcher';
import NotFound from 'src/NotFound';
import { siteResolver } from 'lib/site-resolver';
import clientFactory from 'lib/graphql-client-factory';
import config from 'temp/config';
import { useRouter } from 'next/router';
import { GlobalVideoModal } from 'components/global-video-modal/GlobalVideoModal';

const SitecorePage = ({
  notFound,
  componentProps,
  layoutData,
  headLinks,
}: SitecorePageProps): JSX.Element => {
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);
  const router = useRouter();
  const [unformattedVideoPlayerId, setUnformattedVideoPlayerId] = useState('');
  const [brightcoveIDValue, setBrightcoveIDValue] = useState('');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [isPlaylist, setIsPlaylist] = useState(false);
  const [hash, setHash] = useState('');
  const previousHash = useRef<string | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleHashChange = () => {
        setHash(window.location.hash);
        if (previousHash.current === window.location.hash) {
          setShowVideoModal(true);
        }
        previousHash.current = window.location.hash;
      };

      window.addEventListener('hashchange', handleHashChange);

      return () => {
        window.removeEventListener('hashchange', handleHashChange);
      };
    }
    return undefined;
  }, [router.asPath]);

  useEffect(() => {
    const urlWithoutHash = window.location.href.split('#')[0];
    const videoIdFromHash = window.location.hash.split('#')[1];
    let queryParams = '';
    if (videoIdFromHash) {
      queryParams = videoIdFromHash;

      const hashQueryParams = videoIdFromHash.split('?')[1];
      if (hashQueryParams) {
        queryParams = hashQueryParams;
      }
    } else {
      queryParams = urlWithoutHash.split('?')[1] || '';
    }

    let videoId;
    if (queryParams || videoIdFromHash) {
      const urlParams = new URLSearchParams(queryParams);
      if (
        urlParams.get('playlistId') ||
        (urlParams.get('playlistId') && urlParams.get('videoId'))
      ) {
        videoId = urlParams.get('playlistId');
        setIsPlaylist(true);
      } else if (urlParams.get('videoid') || urlParams.get('videoId')) {
        videoId = urlParams.get('videoid') || urlParams.get('videoId');
        setIsPlaylist(false);
      }
      if (videoId) {
        setUnformattedVideoPlayerId(videoId);
        setBrightcoveIDValue(videoId);
        setShowVideoModal(true);
      }
    }
  }, [router.asPath, unformattedVideoPlayerId, hash]);
  if (notFound || !layoutData.sitecore.route) {
    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
    return <NotFound />;
  }

  const isEditing = layoutData.sitecore.context.pageEditing;

  return (
    <ComponentPropsContext value={componentProps}>
      <SitecoreContext
        componentFactory={componentBuilder.getComponentFactory({ isEditing })}
        layoutData={layoutData}
      >
        <GlobalVideoModal
          brightcoveIDValue={brightcoveIDValue}
          showVideoModal={showVideoModal}
          isPlaylist={isPlaylist}
          setShowVideoModal={setShowVideoModal}
        />
        <Layout layoutData={layoutData} headLinks={headLinks} />
      </SitecoreContext>
    </ComponentPropsContext>
  );
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async (context) => {
  // Fallback, along with revalidate in getStaticProps (below),
  // enables Incremental Static Regeneration. This allows us to
  // leave certain (or all) paths empty if desired and static pages
  // will be generated on request (development mode in this example).
  // Alternatively, the entire sitemap could be pre-rendered
  // ahead of time (non-development mode in this example).
  // See https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration

  const paths: StaticPath[] = [];
  let fallback: boolean | 'blocking' = 'blocking';
  const excludedLanguages = [
    'en-ae',
    'en-au',
    'en-be',
    'en-ch',
    'en-dk',
    'en-fi',
    'en-id',
    'en-ie',
    'en-my',
    'en-nl',
    'en-no',
    'en-pl',
    'en-se',
    'en-sg',
    'en-th',
  ];
  if (process.env.NODE_ENV != 'development' && process.env.DISABLE_SSG_FETCH == 'false') {
    try {
      // Note: Next.js runs export in production mode
      // Hotfix for paths that are not being generated for presentation routes
      // https://rockpapersitecore.com/2023/08/sxa-branch-pages-failing-to-build/
      // https://gyazo.com/8cdd6f32e7312faf52966d82436d2ab0
      const dirtyPaths = await sitemapFetcher.fetch(context);
      dirtyPaths.forEach((p) => {
        //Sitename first, first segment after
        console.log('Trigun', p.params.path);
        if (p.params.path.length > 1) {
          if (
            (p.params.path.length == 2 &&
              p.params.path[1].toLocaleLowerCase() !== 'presentation') ||
            (p.params.path.length > 2 &&
              p.params.path[1].toLocaleLowerCase() !== 'presentation' &&
              p.params.path[2].toLocaleLowerCase() !== 'presentation' &&
              p.params.path[1].toLocaleLowerCase() !== 'data' &&
              p.params.path[2].toLocaleLowerCase() !== 'data' &&
              p.locale &&
              !excludedLanguages.includes(p.locale))
          ) {
            paths.push(p);
          } else {
            console.log('Skipping path:', p);
          }
        }
      });
    } catch (error) {
      console.log('Error occurred while fetching static paths');
      console.log(error);
    }

    fallback = process.env.EXPORT_MODE ? false : fallback;
  }

  return {
    paths,
    fallback,
  };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
  // if (context.params) {
  //   context.params.requestPath = context.params.path;
  //   context.params.path = [`career/job-details/,-w-,`];
  // }
  if (context.params && context.params.path && Array.isArray(context.params.path)) {
    // Create a new object with the path modified
    // console.log('Trigun', context.params.path[0] == 'lang');
    if (context.params.path[0] == 'lang') {
      const props = await sitecorePagePropsFactory.create(context);
      // console.log('TRIGUN-arr', context.params.path[0] == 'lang');
      return {
        props,
        revalidate: true,
        redirect: false,
      };
    }
    // const newPath = context.params.path.filter(
    //   (_item, index) => index !== 1 && locales.includes(_item)
    // );
    // context = {
    //   ...context,
    //   params: {
    //     ...context.params,
    //     path: newPath,
    //   },
    // };
  }

  //context.params?.path?.slice(1, 1);
  // if (context.params?.path) {
  //   const tempLocale: string = context.params?.path[1];
  //   const tempSite: string = context.params?.path[0];
  //   context.params?.path[0] = tempLocale;
  //   context.params?.path[1] = tempSite;
  // }

  const props = await sitecorePagePropsFactory.create(context);
  if (props.notFound) {
    const site = siteResolver.getByName(config.sitecoreSiteName);
    const errorPagesService = new GraphQLErrorPagesService({
      clientFactory,
      siteName: site.name,
      language: context.locale || config.defaultLanguage,
      retries:
        (process.env.GRAPH_QL_SERVICE_RETRIES &&
          parseInt(process.env.GRAPH_QL_SERVICE_RETRIES, 10)) ||
        0,
    });
    const resultErrorPages = await errorPagesService.fetchErrorPages();
    if (resultErrorPages?.notFoundPagePath) {
      const paths = (context?.params?.path as string[]) || [];
      const notFoundUrl = `/${context.locale}/${paths.slice(1).join('/')}`;
      const redirectUrl = `${config.publicUrl}/${context.locale}${resultErrorPages?.notFoundPagePath}?url=${notFoundUrl}`;
      return {
        props,
        revalidate: true,
        redirect: {
          destination: redirectUrl,
          permanent: false,
        },
      };
    }
  }
  return {
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 5 seconds
    revalidate: parseInt(process.env.JSS_REVALIDATE_TIMEOUT || '3600'), // In seconds
    notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

export default SitecorePage;
